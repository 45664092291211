import { defineStore } from 'pinia';
import {
    isEmpty,
    isString,
    removeEmptyStringObjectEntries,
    userRoles,
} from '~/helpers';
import useLoading from '~/lib/hooks/loading';
import useHttp from '~/lib/http';
import {
    BigMentionCode,
    HttpResponseCode,
    UserStatus,
    Permission,
    TherapyType,
    Role,
} from '~/typings/enums';
import { useDatetime } from '~/lib/datetime';
import { getRouter } from '~/lib/router/RouterPipeline';
import TawkChat from '~/lib/chat/TawkChat';
import useRealtimeEvents from '~/lib/realtime';
import useToast from '~/lib/hooks/toast';
import useNotification, { type Notification } from './Notification';
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import type { Modules } from '~/typings/enums';
import type { Uuid } from '~/typings/types';
import type { RawUser, User } from '~/models/User';
import type { HttpError } from '~/typings/http';
import type {
    LoginPayload,
    RegisterPayload,
    ResetPasswordPayload,
    VerifyEmailPayload,
    UpdateAvatarPayload,
    UpdateEmailPayload,
    UpdatePasswordPayload,
    UpdateProfilePayload,
    LoginTwoFactorCodePayload,
    LoginTwoFactorResendPayload,
} from '~/typings/auth';

export interface RawAuthUser extends RawUser {
    permissions: Permission[];
    caregroup_permissions: Permission[];
    unread_notifications: Notification[];
}

export interface TwoFactorResponse {
    two_factor_required: true;
    two_factor_state: string;
}

export interface AuthUser extends User {
    permissions: Set<Permission>;
    caregroup_permissions: Set<Permission>;
    caregroup_modules: Record<Uuid, Modules[]>;
    roles: Set<Role>;
}

export interface PassportTokenResponse {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

const useAuth = defineStore('auth', {
    state: () => ({
        accessToken: null as string | null,
        refreshToken: null as string | null,
        activeCaregroup: null as Uuid | null,
        authenticatedUser: null as RawAuthUser | null,
        redirectAfterLoginRoute: null as RouteLocationNormalized | null,
    }),
    getters: {
        isGuest: state => (!state.authenticatedUser),
        user: state => {
            if (!state.authenticatedUser) {
                return null;
            }

            const authUser: AuthUser = {
                ...state.authenticatedUser,
                permissions: new Set(state.authenticatedUser.permissions),
                caregroup_permissions: new Set(state.authenticatedUser.caregroup_permissions),
                roles: new Set(state.authenticatedUser.roles),
            };

            return authUser;
        },
    },
    actions: {
        setUser(user: RawAuthUser | null) {
            this.authenticatedUser = user;

            if (!user) {
                return;
            }

            const notification = useNotification();
            user.unread_notifications.forEach(item => {
                notification.notifications.set(item.uuid, item);
            });

            if (this.activeCaregroup === null || !user.caregroup_uuids.includes(this.activeCaregroup)) {
                this.activeCaregroup = user.caregroup_uuids[0] ?? null;
            }
        },
        async authenticate() {
            const authenticated = await this.validate(false);

            if (authenticated) {
                useRealtimeEvents().subscribe();
            }
        },
        async validate(redirectIfUnauthenticated = true) {
            return useHttp().get<RawAuthUser | null>('/auth/user')
                .then(data => {
                    this.setUser(data);

                    if (isEmpty(data)) {
                        this.invalidate(redirectIfUnauthenticated, 'expired');

                        return false;
                    }

                    return true;
                })
                .catch(() => {
                    this.invalidate(false, 'expired');

                    return false;
                });
        },
        invalidate(redirectIfUnauthenticated = true, status: 'logout' | 'expired' = 'logout') {
            this.accessToken = null;
            this.refreshToken = null;
            this.authenticatedUser = null;

            if (redirectIfUnauthenticated) {
                getRouter().push({
                    name: 'auth.login',
                    query: {
                        redirect: status,
                    },
                });
            }
        },
        check() {
            return !!this.user?.email_verified_at;
        },
        emailIsNotVerified() {
            return this.user !== null && this.user.email_verified_at === null;
        },
        isApproved() {
            return this.user?.user_status === UserStatus.Approved;
        },
        async csrf() {
            return useHttp().get('/sanctum/csrf-cookie');
        },
        async login(payload: LoginPayload|LoginTwoFactorCodePayload|LoginTwoFactorResendPayload) {
            return useLoading(async () => {
                const alreadyAuthenticated = await this.validate(false);

                if (!alreadyAuthenticated) {
                    const authResponse = await useHttp().post<PassportTokenResponse|TwoFactorResponse>(
                        '/oauth/token',
                        'two_factor_state' in payload ? {
                            two_factor_state: payload.two_factor_state,
                            ...('two_factor_code' in payload ? { two_factor_code: payload.two_factor_code } : { two_factor_resend: true }),
                        } : {
                            grant_type: 'password',
                            client_id: import.meta.env.VITE_PASSPORT_CLIENT_ID,
                            client_secret: import.meta.env.VITE_PASSPORT_CLIENT_SECRET,
                            username: payload.email,
                            password: payload.password,
                        },
                    );

                    if ('two_factor_resend' in payload) {
                        useToast({
                            type: 'success',
                            context: 'toasts.two_factor_resent',
                        });
                    }

                    if ('two_factor_required' in authResponse) {
                        getRouter().push({ name: 'auth.2fa', query: { state: authResponse.two_factor_state } });

                        return Promise.resolve();
                    }

                    if (authResponse.access_token && authResponse.refresh_token) {
                        this.accessToken = authResponse.access_token;
                        this.refreshToken = authResponse.refresh_token;
                    }

                    const userResponse = await useHttp().get<RawAuthUser>('/auth/user');

                    this.setUser(userResponse);
                }

                if (this.hasAnyRole(userRoles())) {
                    TawkChat.api()?.hideWidget();
                }

                let destination: RouteLocationRaw = { name: this.emailIsNotVerified() ? 'auth.verify-email' : 'dashboard' };

                if (destination.name === 'dashboard') {
                    useRealtimeEvents().subscribe();

                    if (this.redirectAfterLoginRoute) {
                        destination = this.redirectAfterLoginRoute;

                        this.setRedirectAfterLoginRoute(null);
                    }
                }

                getRouter().push(destination);

                return Promise.resolve();
            })();
        },
        canRefresh() {
            return !!this.refreshToken && !!this.accessToken;
        },
        async refresh() {
            return useHttp().post<PassportTokenResponse>('/oauth/token', {
                grant_type: 'refresh_token',
                client_id: import.meta.env.VITE_PASSPORT_CLIENT_ID,
                client_secret: import.meta.env.VITE_PASSPORT_CLIENT_SECRET,
                refresh_token: this.refreshToken,
            }).then(data => {
                this.accessToken = data.access_token;
                this.refreshToken = data.refresh_token;
            });
        },
        async register(payload: RegisterPayload) {
            return useLoading(async () => {
                await this.csrf();

                if (payload.big_registration === '') {
                    delete payload.big_registration;
                }

                return useHttp().post<PassportTokenResponse>('/auth/register', payload)
                    .then(async data => {
                        this.accessToken = data.access_token;
                        this.refreshToken = data.refresh_token;

                        const userResponse = await useHttp().get<RawAuthUser>('/auth/user');

                        this.setUser(userResponse);
                        await getRouter().push({ name: 'auth.verify-email' });
                    }).catch((error: HttpError) => {
                        if (error.response.status !== HttpResponseCode.InvalidInput) {
                            throw error;
                        }

                        return null;
                    });
            })();
        },
        async forgotPassword(email: string) {
            return useLoading(async () => {
                await this.csrf();

                return useHttp().post<{ status: string }>('/auth/forgot-password', { email });
            })();
        },
        async resetPassword(payload: ResetPasswordPayload) {
            return useLoading(async () => {
                await this.csrf();

                return useHttp().post<{ status: string }>('/auth/reset-password', payload);
            })();
        },
        async resendEmailVerification() {
            return useHttp().post('/auth/email/verification-notification');
        },
        async logout(redirect = true) {
            return useLoading(async () => {
                this.invalidate(redirect);

                TawkChat.api()?.showWidget();

                useRealtimeEvents().unsubscribe();

                return Promise.resolve();
            })();
        },
        async verifyEmailRequest(id: string, token: string, payload: VerifyEmailPayload) {
            return useLoading(async () => {
                return useHttp().post<{ status: string }>(`/auth/verify-email-request/${id}/${token}`, payload)
                    .then(async () => {
                        const { now } = useDatetime();

                        if (this.authenticatedUser) {
                            this.authenticatedUser.email_verified_at = now().toString();
                        }

                        getRouter().push({ name: 'dashboard' });

                        useToast({
                            type: 'success',
                            context: 'toasts.verified',
                        });

                        return Promise.resolve();
                    });
            })();
        },
        async verifySecondaryEmailRequest(id: string, token: string, payload: VerifyEmailPayload) {
            return useLoading(async () => {
                return useHttp().post<{ status: string }>(`/auth/verify-secondary-email-request/${id}/${token}`, payload)
                    .then(async () => {
                        const { now } = useDatetime();

                        if (this.authenticatedUser) {
                            this.authenticatedUser.secondary_email_verified_at = now().toString();
                        }
                        getRouter().push({ name: 'dashboard' });

                        useToast({
                            type: 'success',
                            context: 'toasts.second_email_verified',
                        });

                        return Promise.resolve();
                    });
            })();
        },
        hasPermissionTo(permission: Permission): boolean {
            if (!this.user) {
                return false;
            }

            return this.user.permissions.has(permission);
        },
        hasAnyPermissionTo(permissions: Permission | Permission[]) {
            if (isString(permissions)) {
                return this.hasPermissionTo(permissions);
            }

            for (const permission of permissions) {
                if (this.hasPermissionTo(permission)) {
                    return true;
                }
            }

            return false;
        },
        hasCaregroupPermission(permission: Permission): boolean {
            if (!this.user) {
                return false;
            }

            return this.user.caregroup_permissions.has(permission);
        },
        can(permission: Permission): boolean {
            return this.hasPermissionTo(permission);
        },
        hasRole(role: Role): boolean {
            if (!this.user) {
                return false;
            }

            return this.user.roles.has(role);
        },
        hasAnyRole(...roles: Role[] | Role[][]): boolean {
            if (!this.user) {
                return false;
            }

            // eslint-disable-next-line no-param-reassign
            roles = roles.flat();

            for (const role of roles) {
                if (this.hasRole(role)) {
                    return true;
                }
            }

            return false;
        },
        hasBigPermissions(therapyType: TherapyType): boolean {
            const bigMentions = this.user?.big_mentions ?? [];

            if (!this.hasPermissionTo(Permission.TherapyGlobal_WithBigMentions_Approve)) {
                return false;
            }

            switch (therapyType) {
                case TherapyType.NebulizationWithoutAntibiotics:
                    return this.hasRole(Role.Nurse) && bigMentions.some(mention => [
                        BigMentionCode.WritingAuthorityAsthmaCOPD,
                        BigMentionCode.WritingAuthorityDiabetesMellitus,
                        BigMentionCode.WritingAuthorityOncology,
                    ].includes(mention.big_mention));
                default:
                    return false;
            }
        },
        hasActiveModule(module: Modules): boolean {
            if (!this.user) {
                return false;
            }

            return Object.values(this.user.caregroup_modules).flat().includes(module);
        },
        async updateEmail(payload: UpdateEmailPayload) {
            if (!this.authenticatedUser) {
                throw new Error('Not authenticated.');
            }

            return useHttp().patch('/auth/user/email', removeEmptyStringObjectEntries(payload))
                .then(() => {
                    if (this.authenticatedUser) {
                        if (payload.email) {
                            this.authenticatedUser.email = payload.email;
                            this.authenticatedUser.email_verified_at = null;
                        }

                        if (payload.secondary_email !== undefined) {
                            this.authenticatedUser.secondary_email = payload.secondary_email;
                            this.authenticatedUser.secondary_email_verified_at = null;
                        }
                    }

                    if (payload.email) {
                        getRouter().push('auth.verify-email');
                    } else if (payload.secondary_email) {
                        useToast({
                            type: 'info',
                            context: 'toasts.second_email_needs_verification',
                        });
                    }
                });
        },
        async updatePassword(payload: UpdatePasswordPayload) {
            if (!this.authenticatedUser) {
                throw new Error('Not authenticated.');
            }

            return useHttp().patch<void>('/auth/user/password', payload);
        },
        async updateProfile(payload: Partial<UpdateProfilePayload>) {
            if (!this.authenticatedUser) {
                throw new Error('Not authenticated.');
            }

            return useHttp().patch<RawAuthUser>('/auth/user', payload)
                .then(data => {
                    this.setUser(data);
                });
        },
        async updateAvatar(payload: UpdateAvatarPayload) {
            return useLoading(async () => {
                const formData = new FormData();
                formData.append('avatar', payload.avatar);

                return useHttp().post<RawAuthUser>(`/users/avatar/${this.authenticatedUser?.uuid}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(data => {
                        this.setUser(data);
                    });
            })();
        },
        async deleteAvatar() {
            return useLoading(async () => {
                return useHttp().delete<RawAuthUser>(`/users/avatar/${this.authenticatedUser?.uuid}`)
                    .then(data => {
                        this.setUser(data);
                    });
            })();
        },
        async toggleRole(role: Role) {
            if (!import.meta.env.DEV) {
                return Promise.resolve();
            }

            return useLoading(async () => {
                try {
                    await useHttp().post('/auth/logout');
                    useRealtimeEvents().unsubscribe();
                    await this.csrf();
                    const authUser = await useHttp().post<RawAuthUser>('/auth/login', {
                        email: `${role}@programic.dev`,
                        password: 'P@55word!',
                        remember: true,
                    });
                    this.setUser(authUser);
                    useRealtimeEvents().subscribe();
                } catch {
                    // Sometimes it will fail, try again
                    const authUser = await useHttp().post<RawAuthUser>('/auth/login', {
                        email: `${role}@programic.dev`,
                        password: 'P@55word!',
                        remember: true,
                    });
                    this.setUser(authUser);
                    useRealtimeEvents().subscribe();
                }

                return Promise.resolve();
            })();
        },
        setRedirectAfterLoginRoute(route: RouteLocationNormalized | null) {
            this.redirectAfterLoginRoute = route;
        },
    },
    persist: true,
});

export default useAuth;
